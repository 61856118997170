
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'

export default defineComponent({
  components: {
    TmModal,
  },
  setup() {
    const importItems = ref([
      {
        image: require('@/assets/images/suite.png'),
      },
      {
        image: require('@/assets/images/slack.png'),
      },
      {
        image: require('@/assets/images/microsoft-teams.png'),
        comingSoon: true,
      },
      {
        image: require('@/assets/images/onelogin.png'),
        comingSoon: true,
      },
      {
        image: require('@/assets/images/azure.png'),
        comingSoon: true,
      },
    ])

    return {
      importItems,
    }
  },
})
